export default {
    namespaced: true,

    state: () => {
        return {
            loadingShifts: false,
            shifts: [],
        }
    },

    getters: {
        loadingShifts(state) {
            return state.loadingShifts;
        },

        shifts(state) {
            return state.shifts;
        },
    },

    mutations: {
        loadingShifts(state, isLoading) {
            state.loadingShifts = isLoading;
        },

        addShifts(state, shifts) {
            state.shifts = shifts;
        },
    },

    actions: {
        async getShifts(ctx, { apiParams = {}, options = {} } = {}) {
            ctx.commit('loadingShifts', true);

            let shifts = await axios.get(`/get_shifts`)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })
            
            ctx.commit('loadingShifts', false);

            if(options.returnData) {
                return shifts;
            }
            
            ctx.commit('addShifts', shifts);
        },

        async shiftManagement(ctx, data){
            let returnData = {isSuccess: false};
            
            await axios.post('/shift_management', data)
            .then(res =>{
                returnData.isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error =>{
                this.dispatch('snackbar/error', error); 
            })

            return returnData;
        },

        async saveShift(ctx, data) {
            
            let returnData = {isSuccess: false};

            await axios.post(data.url, data.shift)
            .then(res => {
                returnData.isSuccess = true;
                ctx.dispatch('getShifts');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                if(error.response.status == 422){
                    let validation_errors = error.response.data;
                    returnData.validation_errors = validation_errors;
                    this.dispatch('snackbar/error', 'Form not validate'); 
                }else{
                    this.dispatch('snackbar/error', error); 
                }
            });

            return returnData;
        },

        async deleteShift(ctx, id) {
            await axios.post(`/delete-shift`, {id})
            .then(res => {
                ctx.dispatch('getShifts');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });
        }
    }
}
