<template>
	<component :is="layout"></component>
</template>

<script>
import defaultLayout from './layouts/Default';
import simpleLayout from './layouts/Simple';
import Login from './views/Login';
export default {
	components: {
		defaultLayout,
		simpleLayout,
		Login
	},

	computed: {
		layout () {
			return this.$route.meta.layout || 'defaultLayout';
		}
	},
}
</script>

<style>

</style>