export default {
    namespaced: true,

    state: () => {
        return {
            loadingEmployees: false,
            employees: [],
            newId: '',
        }
    },

    getters: {
        loadingEmployees(state) {
            return state.loadingEmployees;
        },

        employees(state) {
            return state.employees;
        },

        newId(state) {
            return state.newId;
        }
    },

    mutations: {
        loadingEmployees(state, isLoading) {
            state.loadingEmployees = isLoading;
        },

        addEmployees(state, employees) {
            state.employees = employees;
        },

        addNewEmployeeId(state, id) {
            state.newId = id;
        },
    },

    actions: {
        async getNewEmployeeId(ctx) {
            await axios.get('/new-employee-id')
            .then(res => {
                ctx.commit('addNewEmployeeId', res.data);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })
        },

        async getEmployees(ctx, { apiParams = {}, options = {} } = {}) {
            ctx.commit('loadingEmployees', true);

            let employees = await axios.post(`/get_employees`, apiParams)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            ctx.commit('loadingEmployees', false);

            if(options.returnData) {
                return employees;
            }

            ctx.commit('addEmployees', employees);

        },

        async saveEmployee(ctx, {data = {}, id = 0}) {
            let returnData = {isSuccess: false};

            let url = '/save-employee';
            if(id != 0){
                url = '/update-employee/'+id
            }

            await axios.post(url, data)
            .then(res => {
                this.dispatch('snackbar/success', res.data.message);
                returnData.isSuccess = true;
            })
            .catch(error => {
                if(error.response.status == 422){
                    let validation_errors = error.response.data;
                    returnData.validation_errors = validation_errors;
                    this.dispatch('snackbar/error', 'Form not validate'); 
                }else{
                    this.dispatch('snackbar/error', error); 
                }
            });

            return returnData;
        },
        async deleteEmployee(ctx, data) {
            let returnData = {isSuccess: false};
            await axios.post(`/delete_employee`, data)
            .then(res => {
                this.dispatch('snackbar/success', res.data.message);
                returnData.isSuccess = true;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });

            return returnData;
        },
        async activeEmployee(ctx, data) {
            let returnData = {isSuccess: false};
            await axios.post(`/active_employee`, data)
            .then(res => {
                this.dispatch('snackbar/success', res.data.message);
                returnData.isSuccess = true;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });

            return returnData;
        },
    }
  }
