export default{
    namespaced: true,

    state: () => {
        return {
            loading: false,
            holidays: [],
        }
    },

    getters: {
        loading(state){
            return state.loading;
        },
        holidays(state){
            return state.holidays;
        }
    },

    mutations: {
        updateLoading(state, isLoading){
            state.loading = isLoading;
        },
        addHolidays(state, holidays){
            state.holidays = holidays;
        }
    },

    actions: {
        async saveHoliday(ctx, data){
            let returnData = {isSuccess: false};

            let url = 'add-holiday';
            if(data.id != null) {
                url = 'update-holiday'
            }

            await axios.post(`/${url}`, data)
            .then(res =>{
                this.dispatch('snackbar/success', res.data.message);
                returnData.isSuccess = true;
            }).catch(error =>{
                if(error.response.status == 422){
                    let validation_errors = error.response.data;
                    returnData.validation_errors = validation_errors;
                    this.dispatch('snackbar/error', 'Form not validate'); 
                }else{
                    this.dispatch('snackbar/error', error); 
                }
            })

            return returnData;
        },

        async getHolidays(ctx, {apiParams = {}, options = {}} = {}) {
            ctx.commit('updateLoading', true);

            let holidays = await axios.post('/get-holidays', apiParams)
            .then(res =>{
                return res.data;
            })
            .catch(error => this.dispatch('snackbar/error', error));

            ctx.commit('updateLoading', false);

            if(options.returnData){
                return holidays;
            }

            ctx.commit('addHolidays', holidays);
        },

        async deleteHoliday(ctx, id){
            let isSuccess = false;

            await axios.post('/delete-holiday', {id})
            .then(res =>{
                isSuccess = true;
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error =>{
                this.dispatch('snackbar/error', error);
            })

            return isSuccess;
        },
        
    }
}