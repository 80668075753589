export default {
    namespaced: true,

    state: () => {
        return {
            loadingAreas: false,
            areas: [],
        }
    },

    getters: {
        loadingAreas(state) {
            return state.loadingAreas;
        },

        areas(state) {
            return state.areas;
        },
    },

    mutations: {
        loadingAreas(state, isLoading) {
            state.loadingAreas = isLoading;
        },

        addAreas(state, areas) {
            state.areas = areas;
        },
    },

    actions: {
        async getAreas(ctx) {
            ctx.commit('loadingAreas', true);

            let areas = await axios.get(`/get_areas`)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            ctx.commit('addAreas', areas);

            ctx.commit('loadingAreas', false);
        },

        async saveArea(ctx, data) {

            let returnData = {isSuccess: false};

            await axios.post(data.url, data.area)
            .then(res => {
                returnData.isSuccess = true;
                ctx.dispatch('getAreas');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                if(error.response.status == 422){
                    let validation_errors = error.response.data;
                    returnData.validation_errors = validation_errors;
                    this.dispatch('snackbar/error', 'Form not validate'); 
                }else{
                    this.dispatch('snackbar/error', error); 
                }
            });
            return returnData;
        },

        async deleteArea(ctx, id) {
            await axios.post(`/delete-area`,{id})
            .then(res => {
                ctx.dispatch('getAreas');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });
        }
    }
}
