export default{
    namespaced: true,

    state: {
        salaries: [],
    },

    getters: {
        salaries(state){
            return state.salaries;
        }
    },

    mutations: {
        salaries(state, salaries){
            state.salaries = salaries
        }
    },

    actions: {
        async generateSalary(ctx, data){
            let returnData = {success: false};
            
            await axios.post('/salary_generate', data)
            .then(res => {
                this.dispatch('snackbar/success', res.data.message);
                returnData.success = true;
            })
            .catch(error => {
                if(error.response.status == 422){
                    let validation_errors = error.response.data;
                    returnData.validation_errors = validation_errors;
                    this.dispatch('snackbar/error', 'Form not validate'); 
                }else{
                    this.dispatch('snackbar/error', error); 
                }
            })

            return returnData;
        },
        async getSalaries(ctx, {apiParams = {}, options = {}} = {}){

            let salaries = await axios.post(`/get_salaries`, apiParams)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })


            if(options.returnData) {
                return salaries;
            }

            ctx.commit('salaries', salaries);
        },

        async getSalaryStatements(ctx, {apiParams = {}, options = {}} = {}){
            let statements = await axios.post('/get_salary_statements', apiParams)
            .then(res=>{
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            if(options.returnData){
                return statements;
            }
        },
        async getPaySlips(ctx, {apiParams = {}, options = {}} = {}){
            let statements = await axios.post('/get_pay_slips', apiParams)
            .then(res=>{
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            if(options.returnData){
                return statements;
            }
        },
    }
}