export default {
    namespaced: true,

    state: () => {
        return {
            loadingDesignations: false,
            designations: [],
        }
    },

    getters: {
        loadingDesignations(state) {
            return state.loadingDesignations;
        },

        designations(state) {
            return state.designations;
        },
    },

    mutations: {
        loadingDesignations(state, isLoading) {
            state.loadingDesignations = isLoading;
        },

        addDesignations(state, designations) {
            state.designations = designations;
        },
    },

    actions: {
        async getDesignations(ctx) {
            ctx.commit('loadingDesignations', true);

            let designations = await axios.get(`/get_designations`)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            ctx.commit('addDesignations', designations);

            ctx.commit('loadingDesignations', false);
        },

        async saveDesignation(ctx, data) {
            let returnData = {isSuccess: false};

            await axios.post(data.url, data.designation)
            .then(res => {
                returnData.isSuccess = true;
                ctx.dispatch('getDesignations');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                if(error.response.status == 422){
                    let validation_errors = error.response.data;
                    returnData.validation_errors = validation_errors;
                    this.dispatch('snackbar/error', 'Form not validate'); 
                }else{
                    this.dispatch('snackbar/error', error); 
                }
            });

            return returnData;
        },

        async deleteDesignation(ctx, id) {
            await axios.post(`/delete-designation`, {id})
            .then(res => {
                ctx.dispatch('getDesignations');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });
        }
    }
}
