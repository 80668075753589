export default {
    namespaced: true,

    state: {
        status       : '',
        token        : localStorage.getItem('token') || '',
        user         : localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user'))                  : {},
        role         : localStorage.getItem('role') || '',
        permissions  : localStorage.getItem('permissions') ? JSON.parse(localStorage.getItem('permissions'))    : [],
        shortcutMenus: localStorage.getItem('shortcutMenus') ? JSON.parse(localStorage.getItem('shortcutMenus')): [],
        users        : [],
    },
    getters : {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
        getUser   : state=> state.user,
        getUsers  : state=> state.users,
    },
    mutations: {
        addUsers(state, users) {
            state.users = users;
        },
        update_status(state, status){
            state.status = status
        },
        set_token(state, token){
            state.token = token
        },
        set_user(state, user){
            state.user = user
        },
        set_role(state, role){
            state.role = role
        },
        set_permissions(state, permissions){
            state.permissions = permissions
        },
        shortcutMenus(state, shortcutMenus){
            state.shortcutMenus = shortcutMenus
        },
        logout(state){
            state.status        = ''
            state.token         = ''
            state.user          = {}
            state.role          = ''
            state.permissions   = []
            state.shortcutMenus = []
        },
    },
    actions: {
        async saveUser(ctx, data) {
            
            let returnData = {isSuccess: false};

            await axios.post(data.url, data.user)
            .then(res => {
                returnData.isSuccess = true;
                ctx.dispatch('getUsers');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                if(error.response.status == 422){
                    let validation_errors = error.response.data;
                    returnData.validation_errors = validation_errors;
                    this.dispatch('snackbar/error', 'Form not validate'); 
                }else{
                    this.dispatch('snackbar/error', error); 
                }
            });

            return returnData;
        },
        async getUsers(ctx, { apiParams = {}, options = {} } = {}) {

            let users = await axios.get(`/get_users`)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })
            
            if(options.returnData) {
                return users;
            }
            
            ctx.commit('addUsers', users);
        },
        async getShortcutMenus(ctx){
            let menus = await axios.get('get_shortcut_menus')
            .then(res=>{
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            localStorage.setItem('shortcutMenus', JSON.stringify(Object.values(menus)))
        },
        async updateShortcutMenus(ctx, items){
            await axios.post('update_shorcut_menus', items)
            .then(res=>{
                this.dispatch('snackbar/success', res.data.message);
                ctx.dispatch('getShortcutMenus');
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })
        },
        async login(ctx, user){
            let isSuccess = false;
            ctx.commit('update_status', 'loading');
            await axios.post(`login`, user)
            .then(res => {
                const token = 'Bearer '+res.data.access_token
                const user = res.data.user
                localStorage.setItem('token', token)
                localStorage.setItem('user', JSON.stringify(user))
                localStorage.setItem('role', res.data.role)
                localStorage.setItem('permissions', JSON.stringify(Object.values(res.data.permissions)))
                localStorage.setItem('shortcutMenus', JSON.stringify(Object.values(res.data.shortcutMenus)))
                axios.defaults.headers.common['Authorization'] = token
                ctx.commit('set_token', token)
                ctx.commit('update_status', 'success');
                ctx.commit('set_user',user)
                ctx.commit('set_role',res.data.role)
                ctx.commit('set_permissions',Object.values(res.data.permissions))
                ctx.commit('shortcutMenus',Object.values(res.data.shortcutMenus))
                sessionStorage.removeItem("openPages");
                this.dispatch('company/getCompany');
                isSuccess = true;
            })
            .catch(error => {
                ctx.commit('update_status', 'failed');
                isSuccess = false;
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                localStorage.removeItem('role')
                localStorage.removeItem('permissions')
                localStorage.removeItem('shortcutMenus')
                this.dispatch('snackbar/error', error.response.data.error);
            })    
            return isSuccess;
        },
        async logout({commit}){
            await axios.post('/logout').then(res => {
                commit('logout');
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                localStorage.removeItem('role');
                localStorage.removeItem('permissions');
                localStorage.removeItem('shortcutMenus');
                sessionStorage.removeItem("openPages");
                delete axios.defaults.headers.common['Authorization'];
            })
        },

        async deleteUser(ctx, id) {
            await axios.post(`/delete-user`, {id})
            .then(res => {
                ctx.dispatch('getUsers');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });
        }
    }
}
