export default{
    namespaced: true,

    state: {
        notices: [],
    },
    getters: {
        notices(state){
            return state.notices;
        },
    },
    mutations: {
        notices(state, notices){
            state.notices = notices;
        },
    },
    actions: {
        async saveNotice(ctx, data){
            let returnData = {success: false};
            
            await axios.post(data.url, data.notice)
            .then(res => {
                this.dispatch('snackbar/success', res.data.message);
                returnData.success = true;
            })
            .catch(error => {
                if(error.response.status == 422){
                    let validation_errors = error.response.data;
                    returnData.validation_errors = validation_errors;
                    this.dispatch('snackbar/error', 'Form not validate'); 
                }else{
                    this.dispatch('snackbar/error', error); 
                }
            })

            return returnData;
        },

        async getNotices(ctx, returnData = false){

            let notices = await axios.post(`/get_notices`)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            if(returnData) {
                return notices;
            }
            
            ctx.commit('notices', notices);
        },
        async deleteNotice(ctx, id) {
            await axios.post(`/delete-notice`, {id})
            .then(res => {
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });
        },
    }
}