export default {
    namespaced: true,

    state: () => {
        return {
            loadingDepartments: false,
            departments: [],
        }
    },

    getters: {
        loadingDepartments(state) {
            return state.loadingDepartments;
        },

        departments(state) {
            return state.departments;
        },
    },

    mutations: {
        loadingDepartments(state, isLoading) {
            state.loadingDepartments = isLoading;
        },

        addDepartments(state, departments) {
            state.departments = departments;
        },
    },

    actions: {
        async getDepartments(ctx, { apiParams = {}, options = {} } = {}) {
            ctx.commit('loadingDepartments', true);

            let departments = await axios.get(`/get_departments`)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })
            
            ctx.commit('loadingDepartments', false);

            if(options.returnData) {
                return departments;
            }
            
            ctx.commit('addDepartments', departments);
        },

        async saveDepartment(ctx, data) {
            
            let returnData = {isSuccess: false};

            await axios.post(data.url, data.department)
            .then(res => {
                returnData.isSuccess = true;
                ctx.dispatch('getDepartments');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                if(error.response.status == 422){
                    let validation_errors = error.response.data;
                    returnData.validation_errors = validation_errors;
                    this.dispatch('snackbar/error', 'Form not validate'); 
                }else{
                    this.dispatch('snackbar/error', error); 
                }
            });

            return returnData;
        },

        async deleteDepartment(ctx, id) {
            await axios.post(`/delete-department`, {id})
            .then(res => {
                ctx.dispatch('getDepartments');
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });
        }
    }
}
