
<template>
    <v-snackbar v-model="$store.getters['snackbar/snackbar'].show" :color="$store.getters['snackbar/snackbar'].color" :right="true" :timeout="5000">{{ $store.getters['snackbar/snackbar'].text }}
    <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="$store.commit('snackbar/hide')"
        >
          Close
        </v-btn>
      </template>
      </v-snackbar>
</template>

<script>
export default {}
</script>

<!--<template>
    <v-snackbar v-model="snackObj.show" :color="snackObj.color" :right="true" :timeout="5000">
        {{snackObj.text}}
        <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="updateSnack({show : false, color: '', text: ''})"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
    computed : mapGetters(['snackObj']),
    methods: {
        ...mapActions(['updateSnack']),
    },
}
</script>-->