export default{
    namespaced: true,

    state: {
        promotions: [],
        loadingPromotion: false,
    },
    getters: {
        promotions(state){
            return state.promotions;
        },
        loadingPromotion(state){
            return state.loadingPromotion;
        }
    },
    mutations: {
        promotions(state, promotions){
            state.promotions = promotions;
        },
        loadingPromotion(state, isLoading){
            state.loadingPromotion = isLoading;
        }
    },
    actions: {
        async savePromotion(ctx, data){
            let returnData = {success: false};
            
            await axios.post(data.url, data.promotion)
            .then(res => {
                this.dispatch('snackbar/success', res.data.message);
                returnData.success = true;
            })
            .catch(error => {
                if(error.response.status == 422){
                    let validation_errors = error.response.data;
                    returnData.validation_errors = validation_errors;
                    this.dispatch('snackbar/error', 'Form not validate'); 
                }else{
                    this.dispatch('snackbar/error', error); 
                }
            })

            return returnData;
        },
        async getPromotions(ctx, {apiParams = {}, options = {}} = {}){
            ctx.commit('loadingPromotion', true);

            let promotions = await axios.post(`/get_promotions`, apiParams)
            .then(res => {
                return res.data;
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            })

            ctx.commit('loadingPromotion', false);

            if(options.returnData) {
                return promotions;
            }

            ctx.commit('promotions', promotions);
        },
        async deletePromotion(ctx, id) {
            await axios.post(`/delete_promotion`, {id})
            .then(res => {
                this.dispatch('snackbar/success', res.data.message);
            })
            .catch(error => {
                this.dispatch('snackbar/error', error);
            });
        }
    }
}